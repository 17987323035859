import axios from "axios";
import { END_POINTS } from "./constants";

export const BASE_URL = "http://162.240.239.81:8086/api";
// export const BASE_URL = "http://192.168.1.77:8086/api";

export const imageURL = "http://162.240.239.81:8086";
// export const imageURL = "http://192.168.1.77:8086";

export const role = localStorage.getItem("role");

export const token = localStorage.getItem("token");

export const s3imageUrl =
  "https://panora-s3-bucket.s3.ap-south-1.amazonaws.com";

export const getRequests = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + endPoint, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const updateRequests = async (data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(END_POINTS.Request_URL, data)
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const fetchTotalUsers = async () => {
  // const token = getAuthToken();
  const token = localStorage.getItem("token");
  const response = await axios.get(BASE_URL + "/admin/totalusers", {
    headers: {
      Authorization: `${token}`,
    },
  });
  console.log(response.data.total, "----");
  return response.data.total;
};

const fetchTotalSubAdmins = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BASE_URL + "/admin/totalsubadmins", {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data.total;
};

const fetchTotalPlans = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BASE_URL + "/admin/totalplans", {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data.total;
};

const fetchTotalUserRequests = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BASE_URL + "/admin/totaluserrequests", {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data.total;
};

const fetchTotalIssuedDocs = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BASE_URL + "/admin/totalissueddocs", {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data.total;
};

export {
  fetchTotalUsers,
  fetchTotalSubAdmins,
  fetchTotalPlans,
  fetchTotalUserRequests,
  fetchTotalIssuedDocs,
};
